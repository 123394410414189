import { useEffect, useState } from "react";
import { Product } from "../lib/types";
import { MdOutlineDiscount } from "react-icons/md";
import { RiHeartAdd2Line } from "react-icons/ri";
import { RiHeart3Fill } from "react-icons/ri";
import ClickPropagationBlocker from "./common/ClickPropagationBlocker";
import Price from "./Price";
import Percentage from "./Percentage";
import PriceRating from "./PriceRating";

interface Props {
  product: Product;
  isSelected: boolean;
  handleSelectProduct: (product: Product) => void;
}

const Card = ({ product, isSelected, handleSelectProduct }: Props) => {
  const [isLiked, setIsLiked] = useState<boolean>(false);

  const hasDiscount = product.discount > 0;
  const isSold = product.sold;

  useEffect(() => {
    const likedProducts =
      JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
    setIsLiked(likedProducts.includes(product.id));
  }, [product.id]);

  const toggleHeart = () => {
    const likedProducts: string[] =
      JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
    if (isLiked) {
      const updatedProducts = likedProducts.filter((id) => id !== product.id);
      localStorage.setItem("likedProducts", JSON.stringify(updatedProducts));
    } else {
      likedProducts.push(product.id);
      localStorage.setItem("likedProducts", JSON.stringify(likedProducts));
    }
    setIsLiked(!isLiked);
  };

  const isNewProduct = () => {
    try {
      const now = new Date();
      const productDate = new Date(product.createdAt.seconds * 1000);
      const timeDifference = now.valueOf() - productDate.valueOf();
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      return hoursDifference < 24 * 7;
    } catch (error) {
      console.error("Error in isNewProduct:", error, "Product:", product);
      return false;
    }
  };

  let discountPercentage = 0.0;
  if (hasDiscount) {
    discountPercentage = (product.price - product.discount) / product.price;
  }

  return (
    <div
      className={`overflow-hidden flex flex-col w-full h-full hover:cursor-pointer ${
        isSelected ? "border-compareColor border rounded-lg shadow-md" : ""
      }`}
    >
      <div className="relative w-full">
        <div className="w-full aspect-square overflow-hidden rounded-lg bg-white">
          <img
            loading="lazy"
            src={product.thumbnailUrl}
            className="w-full object-cover transition-transform duration-300 ease-in-out hover:scale-105 rounded"
            alt=""
          />
        </div>
        {isNewProduct() && (
          <div className="absolute transform -rotate-45 bg-accentLight text-center text-accentDark font-sans font-semibold text-[12px] left-[-80px] top-[10px] w-[200px]">
            New
          </div>
        )}
        {isSold ? (
          <p className="font-sans text-[12px] font-semibold p-1 absolute top-2 right-2 rounded-full px-2 bg-gray-300 text-black">
            Sold
          </p>
        ) : hasDiscount ? (
          <div className="flex font-sans text-xs font-semibold p-1 absolute top-1 right-1 rounded-full px-2 bg-discount text-white align-middle items-center">
            <MdOutlineDiscount className="mr-0.5 text-sm" />
            <p>
              <Percentage value={discountPercentage} />
            </p>
          </div>
        ) : null}
        <ClickPropagationBlocker>
          <button
            id="card-like-button"
            className={`absolute bottom-2 right-2 ${
              isLiked ? "text-discount" : "text-black"
            } bg-white rounded-full p-2 drop-shadow hover:border border-gray-400`}
            onClick={toggleHeart}
          >
            {isLiked ? (
              <RiHeart3Fill className="text-xl" />
            ) : (
              <RiHeartAdd2Line className="text-xl" />
            )}
          </button>
        </ClickPropagationBlocker>
      </div>
      <div className="flex flex-grow flex-row py-1 px-1">
        <div className="flex flex-col flex-grow leading-normal">
          <div className="flex flex-col flex-grow">
            <p className="font-serif font-medium text-base sm:text-lg uppercase">
              {product.brand}
            </p>
            <p className="font-sans text-sm sm:text-base">{product.model}</p>
            <p className="font-sans text-sm sm:text-base">
              {product.condition}
            </p>
            {hasDiscount ? (
              <div className="flex flex-row mt-2">
                <p className="font-sans text-sm sm:text-base font-bold mr-2 text-discount">
                  <Price
                    amount={product.discount}
                    currency={product.currency}
                  />
                </p>
                <p className="font-sans text-xs sm:text-sm mt-1 mr-2 text-gray-500 line-through decoration-1">
                  <Price amount={product.price} currency={product.currency} />
                </p>
              </div>
            ) : (
              <div className="mt-1">
                <p className="font-sans text-sm sm:text-base font-bold ">
                  <Price amount={product.price} currency={product.currency} />
                </p>
              </div>
            )}
            <div className="">
              <PriceRating product={product} />
            </div>
            <p className="font-sans text-sm sm:text-base italic text-gray-400 mt-2">
              By {product.store}
            </p>
          </div>
        </div>
      </div>
      <ClickPropagationBlocker>
        <div
          className="flex font-sans text-sm py-1 px-2 align-middle"
          onClick={() => handleSelectProduct(product)}
        >
          <input
            key={`${product.id + isSelected.toString()}`}
            type="checkbox"
            checked={isSelected}
            readOnly
            className="accent-black"
          />
          <label className="ml-2 cursor-pointer">Compare</label>
        </div>
      </ClickPropagationBlocker>
    </div>
  );
};

export default Card;
