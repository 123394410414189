import { useEffect, useRef, useState } from "react";
import { db } from "../lib/firebase";
import {
  collection,
  getDocs,
  limit,
  query as firestoreQuery,
  where,
} from "firebase/firestore";
import { Product } from "../lib/types";
import Grid from "../components/products/Grid";
import FilterMenu from "../components/FilterMenu";
import {
  useNavigationType,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { categoryMap } from "../lib/types";
import FilterBar from "../components/FilterBar";
import Pagination from "../components/Pagination";
import Search from "../components/products/Search";
import {
  createQueryFromSearchParams,
  createSearchParamsFromQuery,
  defaultQuery,
  Query,
  search,
} from "../lib/search";
import FilterButton from "../components/products/FilterButton";
import SortDropdown from "../components/products/SortDropdown";
import FilterPills from "../components/products/FilterPills";
import { OutletContext } from "../App";

export default function ProductsPage() {
  const { scrollTop } = useOutletContext<OutletContext>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const [back, setBack] = useState<boolean>(false);

  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<Query>(defaultQuery);
  const productsPerPage = 30;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [totalPages, setTotalPages] = useState<number>(0);

  const [searchInput, setSearchInput] = useState<string>("");
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const isMounted = useRef(false);

  const fetchData = async (query: Query) => {
    setProducts([]);
    setLoading(true);
    const { hits: ids, totalPages } = await search(
      query,
      query.page,
      productsPerPage
    );
    if (ids.length === 0) {
      setLoading(false);
      return;
    }

    const productsQuery = firestoreQuery(
      collection(db, "products"),
      where("__name__", "in", ids),
      limit(productsPerPage)
    );
    const snapshot = await getDocs(productsQuery);
    const products = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Product[];

    if (query.sortBy) {
      switch (query.sortBy.value) {
        case "priceAsc":
          products.sort((a: Product, b: Product) => a.sortPrice - b.sortPrice);
          break;
        case "priceDesc":
          products.sort((a: Product, b: Product) => b.sortPrice - a.sortPrice);
          break;
        case "priceDiff":
          products.sort(
            (a: Product, b: Product) =>
              a.percentageDifference - b.percentageDifference
          );
          break;
        case "conditionAsc":
          products.sort((a: Product, b: Product) => a.quality - b.quality);
          break;
        case "conditionDesc":
          products.sort((a: Product, b: Product) => b.quality - a.quality);
          break;
        case "brand":
          products.sort((a: Product, b: Product) =>
            a.brand.localeCompare(b.brand)
          );
          break;
        case "createdAt":
          products.sort(
            (a: Product, b: Product) =>
              b.createdAt.toMillis() - a.createdAt.toMillis()
          );
          break;
      }
    }

    setProducts(products);
    setTotalPages(totalPages);
    setLoading(false);
  };

  useEffect(() => {
    console.log("mounting begin");
    if (navigationType === "POP") setBack(true);
    console.log("searchParams:", searchParams);
    const query = createQueryFromSearchParams(searchParams);
    setQuery(query);
    setSearchInput(query.search);
    fetchData(query);
    return () => {
      console.log("unmounting");
    };
  }, []);

  useEffect(() => {
    console.log("Query changed:", query);

    if (!isMounted.current) {
      console.log("tried to update search params");
      return;
    }

    console.log("updated search params and fetch data");

    const params = createSearchParamsFromQuery(query);
    setSearchParams(params, { replace: true });
    fetchData(query);
  }, [query]);

  useEffect(() => {
    if (!isMounted.current) {
      console.log("tried to fetch data from search field");
      return;
    }

    console.log("fetch data from search field");

    if (searchInput === "") {
      setQuery({ ...query, search: searchInput });
      fetchData(query);
    } else {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        if (searchInput) {
          setQuery({ ...query, search: searchInput });
        }
      }, 1000);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchInput]);

  useEffect(() => {
    console.log("mounted done");
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (!loading) {
      if (back) {
        console.log("scrolling to last product");
        console.log(scrollTop);
        window.scrollTo(0, scrollTop.current);
        setBack(false);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [loading, back]);

  const handlePageChange = (page: number) => {
    setQuery({ ...query, page: page });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  };

  return (
    <div className={`min-h-screen flex flex-col pb-16`}>
      <main className="flex-grow p-4 container mx-auto">
        <Search searchInput={searchInput} setSearchInput={setSearchInput} />

        <h1 className="text-3xl font-serif mt-4 mb-2 text-center">
          {query.category ? categoryMap[query.category.value] : "All Products"}
        </h1>
        <div className="flex flex-row mb-2 items-center justify-between">
          {false ? (
            <div className="my-2">
              <FilterBar query={query} setQuery={setQuery} />
            </div>
          ) : (
            <FilterButton toggleFilter={toggleFilter} />
          )}
          <SortDropdown
            label={query.sortBy?.label}
            query={query}
            setQuery={setQuery}
          />
        </div>

        <FilterMenu
          query={query}
          setQuery={setQuery}
          isOpen={isFilterOpen}
          close={() => {
            setIsFilterOpen(false);
          }}
        />

        <div className="mb-4">
          <FilterPills query={query} setQuery={setQuery} />
        </div>

        {!loading && products.length === 0 ? (
          <div className="flex justify-center items-center h-full">
            <p className="font-sans">{`No products found :(`}</p>
          </div>
        ) : (
          <>
            <Grid
              products={products}
              loading={loading}
              numProducts={productsPerPage}
            />
            <Pagination
              totalPages={totalPages}
              currentPage={query.page}
              onPageChange={handlePageChange}
            />
            {!loading && products.length > 0 && (
              <div className="flex justify-center items-center mt-4">
                <span className="mr-2 text-sm">Powered by</span>
                <img
                  src="/algolia.png"
                  alt="Powered by Algolia"
                  width={30}
                  height={30}
                />
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
}
