import { Timestamp } from "firebase/firestore";

export interface Product {
  id: string;
  store: string;
  brand: string;
  model: string;
  condition: string;
  price: number;
  discount: number;
  imageUrl: string;
  thumbnailUrl: string;
  productUrl: string;
  sold: boolean;
  soldAt: Timestamp;
  createdAt: Timestamp;
  currency: string;
  description: string;
  imageUrls: string[];
  comesWith: string;
  length: number;
  height: number;
  width: number;
  conditionDescription: string;
  material: string;
  color: string;
  category: string;
  categories: string[];
  sortPrice: number;
  quality: number;
  averagePrice: number;
  priceDifference: number;
  percentageDifference: number;
}

export interface Option {
  value: string;
  label: string;
}

export const conditionMap: Option[] = [
  { value: "Fair", label: "Fair" },
  { value: "Good", label: "Good" },
  { value: "Very Good", label: "Very Good" },
  { value: "Excellent", label: "Excellent" },
  { value: "Like New", label: "Like New" },
  { value: "Unused", label: "Unused" },
];

export const conditionToQualityMap: { [key: string]: number } = {
  Fair: 1,
  Good: 2,
  "Very Good": 3,
  Excellent: 4,
  "Like New": 5,
  Unused: 6,
};

export const categoryOptions: Option[] = [
  { value: "hand-bag", label: "Handbags" },
  { value: "shoulder-bag", label: "Shoulder bags" },
  { value: "tote-bag", label: "Totes" },
  { value: "backpack", label: "Backpacks" },
  { value: "belt-bag", label: "Belt bags" },
  { value: "weekend-bag", label: "Weekend bags" },
  { value: "clutch", label: "Clutches" },
  { value: "crossbody-bag", label: "Crossbody bags" },
];

export const categoryMap: { [key: string]: string } = {
  "hand-bag": "Handbags",
  "shoulder-bag": "Shoulder bags",
  "tote-bag": "Totes",
  backpack: "Backpacks",
  "belt-bag": "Belt bags",
  "weekend-bag": "Weekend bags",
  clutch: "Clutches",
  "crossbody-bag": "Crossbody bags",
};

export const brandMap: Option[] = [
  { value: "Balenciaga", label: "Balenciaga" },
  { value: "Christian Dior", label: "Christian Dior" },
  { value: "Celine", label: "Celine" },
  { value: "Balmain", label: "Balmain" },
  { value: "Chloé", label: "Chloé" },
  { value: "Gucci", label: "Gucci" },
  { value: "Fendi", label: "Fendi" },
  { value: "Hermès", label: "Hermès" },
  { value: "Louis Vuitton", label: "Louis Vuitton" },
  { value: "Prada", label: "Prada" },
  { value: "Chanel", label: "Chanel" },
  { value: "Bottega Veneta", label: "Bottega Veneta" },
  { value: "Goyard", label: "Goyard" },
  { value: "Jacquemus", label: "Jacquemus" },
  { value: "Loewe", label: "Loewe" },
  { value: "Miu Miu", label: "Miu Miu" },
  { value: "Saint Laurent", label: "Yves Saint Laurent" },
  { value: "Burberry", label: "Burberry" },
  { value: "Versace", label: "Versace" },
  { value: "Mulberry", label: "Mulberry" },
  { value: "Totême", label: "Totême" },
  // { value: 'Christian Louboutin', label: 'Christian Louboutin' },
  // { value: 'Furla', label: 'Furla' },
  // { value: 'Mcm', label: 'Mcm' },
  // { value: 'Bvlgari', label: 'Bvlgari' },
  // { value: 'Jimmy Choo', label: 'Jimmy Choo' },
  // { value: 'Marc Jacobs', label: 'Marc Jacobs' },
  // { value: 'Cartier', label: 'Cartier' },
  // { value: 'Dkny', label: 'Dkny' },
  // { value: 'Carolina Herrera', label: 'Carolina Herrera' },
  // { value: 'Longchamp', label: 'Longchamp' },
  // { value: 'Marni', label: 'Marni' },
  // { value: 'Tory Burch', label: 'Tory Burch' },
  // { value: 'Alexander Wang', label: 'Alexander Wang' },
  // { value: 'Moncler', label: 'Moncler' },
  // { value: 'Stella McCartney', label: 'Stella McCartney' },
  // { value: 'Coach', label: 'Coach' },
  // { value: 'Salvatore Ferragamo', label: 'Salvatore Ferragamo' },
  // { value: 'Michael Kors', label: 'Michael Kors' },
  // { value: 'Kate Spade', label: 'Kate Spade' },
  // { value: 'Givenchy', label: 'Givenchy' },
  // { value: 'Bally', label: 'Bally' },
  // { value: 'Dolce & Gabbana', label: 'Dolce & Gabbana' },
  // { value: 'Valentino', label: 'Valentino' },
  // { value: "Tod's", label: "Tod's" },
  // { value: 'Alexander McQueen', label: 'Alexander McQueen' },
  // { value: 'A.P.C.', label: 'A.P.C.' },
  // { value: 'Rolex', label: 'Rolex' },
  // { value: 'Etro', label: 'Etro' },
  // { value: 'Off white', label: 'Off white' },
  // { value: 'Lancel', label: 'Lancel' },
  // { value: 'Maison Margiela', label: 'Maison Margiela' },
  // { value: 'Armani', label: 'Armani' },
  // { value: 'Anya Hindmarch', label: 'Anya Hindmarch' },
  // { value: 'Staud', label: 'Staud' },
  // { value: 'Moschino', label: 'Moschino' },
  // { value: 'Osoi', label: 'Osoi' },
  // { value: 'Proenza Schouler', label: 'Proenza Schouler' },
  // { value: 'Elleme', label: 'Elleme' },
  // { value: 'Lanvin', label: 'Lanvin' },
  // { value: 'By far', label: 'By far' },
  // { value: 'Isabel Marant', label: 'Isabel Marant' },
  // { value: 'Jil Sander', label: 'Jil Sander' },
  // { value: 'Ralph Lauren', label: 'Ralph Lauren' },
  // { value: 'The Row', label: 'The Row' },
  // { value: 'Cole Haan', label: 'Cole Haan' },
  // { value: 'Courrèges', label: 'Courrèges' },
  // { value: 'Jérôme Dreyfuss', label: 'Jérôme Dreyfuss' },
  // { value: 'Max Mara', label: 'Max Mara' },
  // { value: 'Phillip Lim', label: 'Phillip Lim' },
  // { value: 'Vivienne Westwood', label: 'Vivienne Westwood' },
  // { value: 'Zadig & Voltaire', label: 'Zadig & Voltaire' },
  // { value: 'Delvaux', label: 'Delvaux' },
  // { value: 'Paule Ka', label: 'Paule Ka' },
  // { value: 'Roberto Cavalli', label: 'Roberto Cavalli' },
  // { value: 'Wandler', label: 'Wandler' },
  // { value: 'Acne Studios', label: 'Acne Studios' },
  // { value: 'Alaïa', label: 'Alaïa' },
  // { value: 'Anine Bing', label: 'Anine Bing' },
  // { value: 'Blumarine', label: 'Blumarine' },
  // { value: 'Calvin Klein', label: 'Calvin Klein' },
  // { value: 'Cult Gaia', label: 'Cult Gaia' },
  // { value: 'Loro Piana', label: 'Loro Piana' },
  // { value: 'Maje', label: 'Maje' },
  // { value: 'Mansur Gavriel', label: 'Mansur Gavriel' },
  // { value: 'Nanushka', label: 'Nanushka' },
  // { value: 'Paul Smith', label: 'Paul Smith' },
  // { value: 'Vanessa Bruno', label: 'Vanessa Bruno' },
  // { value: 'Baldinini', label: 'Baldinini' },
  // { value: 'Claris Virot', label: 'Claris Virot' },
  // { value: 'Diesel', label: 'Diesel' },
  // { value: 'Dunhill', label: 'Dunhill' },
  // { value: 'Ganni', label: 'Ganni' },
  // { value: 'Golden Goose', label: 'Golden Goose' },
  // { value: 'Joseph', label: 'Joseph' },
  // { value: 'JW Anderson', label: 'JW Anderson' },
  // { value: 'Nina Ricci', label: 'Nina Ricci' },
  // { value: 'Palm Angels', label: 'Palm Angels' },
  // { value: 'Patrizia Pepe', label: 'Patrizia Pepe' },
  // { value: 'Pierre Hardy', label: 'Pierre Hardy' },
  // { value: 'Smythson', label: 'Smythson' },
  // { value: 'Sonia Rykiel', label: 'Sonia Rykiel' },
];

export const sortMap: Option[] = [
  { value: "priceDiff", label: "Best Value" },
  { value: "priceAsc", label: "Price (low to high)" },
  { value: "priceDesc", label: "Price (high to low)" },
  { value: "conditionAsc", label: "Condition (low to high)" },
  { value: "conditionDesc", label: "Condition (high to low)" },
  { value: "createdAt", label: "New" },
  { value: "brand", label: "Brand" },
];
