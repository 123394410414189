import React, { ReactNode, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";

interface Props {
  images: string[];
  children?: ReactNode;
}

export default function Carousel({ images, children }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [deltaX, setDeltaX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const handleSwiping = (data: { deltaX: number }) => {
    if (!isTransitioning) {
      setIsSwiping(true);
      setDeltaX(data.deltaX);
    }
  };

  const handleSwiped = (data: { velocity: number }) => {
    if (data.velocity > 0.3 || Math.abs(deltaX) > 50) {
      if (deltaX < 0) {
        goToNext();
      } else {
        goToPrevious();
      }
    } else {
      resetPosition();
    }
    setIsSwiping(false);
  };

  const resetPosition = () => {
    setDeltaX(0);
    setIsTransitioning(true);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const goToNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setDeltaX(0);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const goToPrevious = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
    setDeltaX(0);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const goToIndex = (index: number) => {
    if (isTransitioning) return;
    setCurrentIndex(index);
    setDeltaX(0);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const handlers = useSwipeable({
    onSwiping: (eventData) => handleSwiping(eventData),
    onSwiped: (eventData) => handleSwiped(eventData),
    preventScrollOnSwipe: true,
  });

  return (
    <div
      className="relative aspect-square w-full mx-auto overflow-hidden"
      {...handlers}
    >
      <div className="relative">
        <div
          className={`relative flex ${
            !isSwiping ? "transition-transform duration-300 ease-in-out" : ""
          }`}
          style={{
            transform: `translateX(calc(-${
              currentIndex * 100
            }% + ${deltaX}px))`,
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className="w-full flex-shrink-0 object-cover aspect-square"
            />
          ))}
        </div>
        {!isDesktop && (
          <div className="absolute bottom-4 w-full flex justify-center space-x-2">
            {images.map((_, index) => (
              <span
                key={index}
                className={`w-3 h-3 rounded-full cursor-pointer ${
                  index === currentIndex ? "bg-gray-800" : "bg-gray-400"
                }`}
                onClick={() => goToIndex(index)}
              ></span>
            ))}
          </div>
        )}
        {children}
      </div>

      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        onClick={goToPrevious}
        disabled={isTransitioning}
      >
        &#10094;
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        onClick={goToNext}
        disabled={isTransitioning}
      >
        &#10095;
      </button>

      {isDesktop && (
        <div className="mt-4 flex justify-center space-x-2">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={`w-16 h-16 object-cover cursor-pointer ${
                index === currentIndex
                  ? "border-2 border-black"
                  : "border-2 border-transparent"
              }`}
              onClick={() => goToIndex(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
