import { useState } from "react";
import { RiSortDesc } from "react-icons/ri";
import { sortMap, Option } from "../../lib/types";
import { Query } from "../../lib/search";

interface Props {
  label?: string;
  query: Query;
  setQuery: (query: Query) => void;
}

export default function SortDropdown({ label, query, setQuery }: Props) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <div
      className="flex items-center relative cursor-pointer px-2 py-1"
      tabIndex={0}
      onClick={() => setShowDropdown(!showDropdown)}
      onBlur={() => setShowDropdown(false)}
    >
      <p className="align-middle font-sans mr-1">{label ?? "Sort by"}</p>
      <RiSortDesc className="text-2xl cursor-pointer" />
      {showDropdown && (
        <div className="absolute right-0 top-8 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sortMap.map((option: Option, index: number) => (
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setQuery({ ...query, sortBy: option });
                  setShowDropdown(false);
                }}
                key={index}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
