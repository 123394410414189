import React from "react";
import { Product } from "../../lib/types";

function BuyButton({ product }: { product: Product }) {
  return (
    <button
      className="btn btn-primary"
      id="buy-button"
      title={`Buy now at ${product.store}`}
      onClick={() => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "purchase",
            product_id: product.id,
            product_name: `${product.brand} ${product.model}`,
            product_price: product.price,
            product_discount: product.discount,
            product_currency: product.currency,
            brand: product.brand,
            model: product.model,
            store: product.store,
            condition: product.condition,
            url: product.productUrl,
            material: product.material,
            color: product.color,
            categories: product.categories,
          });
        }
        window.open(`${product.productUrl}`, "_blank");
      }}
    >
      {`Buy now at ${product.store}`}
    </button>
  );
}

export default BuyButton;
