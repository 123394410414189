import React from "react";
import { FaBalanceScaleLeft } from "react-icons/fa";

interface Props {
  selectedProducts: number;
  canStartComparing: boolean;
  startComparing: () => void;
  clearSelectedProducts: () => void;
}

const CompareFooter = ({
  selectedProducts,
  canStartComparing,
  startComparing,
  clearSelectedProducts,
}: Props) => {
  return (
    <div className="flex fixed bottom-0 w-full bg-white h-16 px-2 items-center shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]">
      <div className="flex-1">
        <button
          disabled={!canStartComparing}
          onClick={startComparing}
          className={`${
            canStartComparing
              ? "bg-black text-white"
              : "bg-black text-white opacity-50"
          } border rounded-md p-2 px-4 font-sans`}
        >
          Compare
        </button>
      </div>
      <div className="flex-grow text-center font-sans text-sm">
        {`${selectedProducts} of 2 products selected`}
      </div>
      <div className="flex text-right">
        <button
          onClick={clearSelectedProducts}
          className="text-black p-2 font-sans underline"
        >
          <span>Clear All</span>
        </button>
      </div>
    </div>
  );
};

export default CompareFooter;
