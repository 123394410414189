import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiHeart3Fill, RiHeart3Line, RiMenuFill } from "react-icons/ri";

interface Props {
  toggleMenu: () => void;
}

const MenuItem = ({ label, to }: { label: string; to: string }) => {
  return (
    <Link to={to} reloadDocument>
      <div className="flex mr-4 font-sans uppercase text-sm tracking-wider items-center cursor-pointer">
        <p>{label}</p>
      </div>
    </Link>
  );
};

const Header = ({ toggleMenu }: Props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(true);
  const [top, setTop] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const threshold = 10;

  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const isLikedPage = location.pathname === "/liked";
  const isProductsPage = location.pathname === "/products";
  const isProductPage = location.pathname.startsWith("/product");

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY === 0) {
      setShow(true);
      setTop(true);
    } else if (currentScrollY <= threshold) {
      setShow(true);
      setTop(false);
    } else if (
      currentScrollY > lastScrollY &&
      currentScrollY - lastScrollY > threshold
    ) {
      setShow(false);
      setTop(false);
    } else if (lastScrollY - currentScrollY > threshold) {
      setShow(true);
      setTop(false);
    }

    if (!isProductsPage && !isProductPage && !isLikedPage) {
      setShow(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <header
      className={`fixed w-full h-24 text-center ${
        !isLandingPage
          ? "bg-background text-black"
          : top
          ? "bg-transparent text-white"
          : "bg-background text-black"
      } z-40 transition-transform duration-300 ${
        show
          ? "transform translate-y-0"
          : isLandingPage
          ? "transform translate-y-0"
          : "transform -translate-y-full"
      }`}
    >
      <div className="h-14 flex items-center justify-between px-2 shadow">
        <button className="p-2 " onClick={toggleMenu}>
          <RiMenuFill className="w-6 h-6 " />
        </button>
        <div className="flex flex-col text-center">
          <span
            className="font-serif text-4xl uppercase tracking-widest cursor-pointer "
            onClick={() => navigate("/")}
          >
            Avenura
          </span>
          {/* <p className="font-serif text-md uppercase">
                The Designer Marketplace
              </p> */}
        </div>
        <button className="p-2" onClick={() => navigate("/liked")}>
          {isLikedPage ? (
            <RiHeart3Fill className="w-6 h-6 text-heartColor" />
          ) : (
            <RiHeart3Line className="w-6 h-6 " />
          )}
        </button>
      </div>
      <div
        className={`h-10 flex ${
          !isLandingPage
            ? "bg-background border-t border-t-black text-black"
            : top
            ? "bg-transparent border-t border-white"
            : "bg-background border-t border-t-black text-black"
        } px-2 py-2 overflow-x-auto whitespace-nowrap pl-4 overscroll-y-none justify-center align-middle`}
      >
        {/* <MenuItem label="New In" to="/products?sort=createdAt" /> */}
        <MenuItem label="Bags" to="/products" />
        <MenuItem label="Brands" to="/brands" />
        {/* <MenuItem label="Sale" to="/products?discounted=true" /> */}
        <MenuItem label="Blog" to="/blog" />
        <MenuItem label="Partners" to="/partners" />
        {/* <MenuItem label="About" to="/about" /> */}
      </div>
    </header>
  );
};

export default Header;
