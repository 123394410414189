import React, { useEffect, useState } from "react";
import { Product } from "../lib/types";
import Carousel from "./Carousel";
import Button from "./common/Button";
import { RiArrowDownSLine, RiZoomInLine } from "react-icons/ri";
import Price from "./Price";
import PriceRating from "./PriceRating";
import BuyButton from "./common/BuyButton";

interface InfoProps {
  label: string;
  text: string;
}

const Info = ({ label, text }: InfoProps) => {
  function formatText(description: string) {
    return description.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <div className="border-b mt-4 pb-2">
      <p className="font-sans text-lg font-bold">{label}</p>
      <p className="font-sans text-md">{formatText(text)}</p>
    </div>
  );
};

interface PriceInfoProps {
  price: number;
  discount: number;
  currency: string;
}

const PriceInfo = ({ price, discount, currency }: PriceInfoProps) => (
  <div className="border-b mt-4 pb-2">
    <p className="font-sans text-lg font-bold">Price</p>
    {discount ? (
      <p className="font-sans text-md">
        <span className="text-discount mr-2">
          <Price amount={discount} currency={currency} />
        </span>
        <span className="text-gray-500 line-through decoration-1">
          <Price amount={price} currency={currency} />
        </span>
      </p>
    ) : (
      <p className="font-sans text-md">
        <Price amount={price} currency={currency} />
      </p>
    )}
  </div>
);

const PriceRatingInfo = ({ product }: { product: Product }) => (
  <div className="border-b mt-4 pb-2">
    <p className="font-sans text-lg font-bold">Price Rating</p>
    <PriceRating product={product} />
  </div>
);

interface ConditionInfoProps {
  condition: string;
  conditionDescription: string;
}

const ConditionInfo = ({
  condition,
  conditionDescription,
}: ConditionInfoProps) => (
  <div className="border-b mt-4 pb-2">
    <p className="font-sans text-lg font-bold">Condition</p>
    <p className="font-sans text-md">{condition}</p>
    {conditionDescription ? (
      <p className="font-sans text-md mt-2">{conditionDescription}</p>
    ) : null}
  </div>
);

interface DimensionsInfoProps {
  length: number;
  width: number;
  height: number;
}

const DimensionsInfo = ({ length, width, height }: DimensionsInfoProps) => (
  <div className="border-b mt-2 pb-2">
    <p className="font-sans text-lg font-bold">Dimensions</p>
    {/* <p className="font-sans text-md">
      L{length} x W{width} x H{height} cm
    </p> */}
    <div className=" grid grid-cols-[3em_4em_auto] font-sans text-md">
      <p>Length</p>
      <p className="text-right">{length} cm</p>
      <p></p>
      <p>Width</p>
      <p className="text-right">{width} cm</p>
      <p></p>
      <p>Height</p>
      <p className="text-right">{height} cm</p>
      <p></p>
    </div>
  </div>
);

const ImageWithSkeleton = ({
  src,
  onClick,
}: {
  src: string;
  onClick: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative w-full aspect-square">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse"></div>
      )}
      <img
        src={src}
        className={`object-cover w-full h-full cursor-pointer ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
        onLoad={() => setIsLoading(false)}
        onClick={onClick}
      />
    </div>
  );
};

interface Props {
  products: Product[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  handleClose: () => void;
}

const Compare = ({ products, setSelectedProducts, handleClose }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [carouselImages, setCarouselImages] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const a = products[0];
  const b = products[1];

  const handleClickImage = (images: string[], index: number) => {
    setCarouselImages(images);
    setCurrentIndex(index);
    setShowCarousel(true);
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const close = () => {
    setIsVisible(false);
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  const clearAll = () => {
    setIsVisible(false);
    setSelectedProducts([]);
    handleClose();
  };

  return (
    <div>
      <div className="fixed inset-0 z-50 flex justify-center items-end">
        <div
          className={`relative w-full h-full bg-background  overflow-hidden transition-transform duration-300 ease-out ${
            isVisible ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {" "}
          <div className="flex flex-col h-full">
            <div className="fixed h-16 top-0 left-0 w-full bg-white z-40 py-4 px-4 shadow flex items-center justify-between">
              <div className="flex items-center space-x-2" onClick={close}>
                <RiArrowDownSLine className="text-2xl" />
                <h1 className="text-lg font-bold">Compare Products</h1>
              </div>
              <button onClick={clearAll} className="underline">
                Clear All
              </button>
            </div>
            <div className="mt-16 overflow-y-auto px-3 pt-4 pb-8">
              <div className="relative grid grid-cols-2 gap-2">
                <div className="relative w-full h-full flex items-center justify-center">
                  <ImageWithSkeleton
                    src={a.thumbnailUrl}
                    onClick={() =>
                      handleClickImage([a.imageUrl, ...a.imageUrls], 0)
                    }
                  />
                  <div className="absolute bottom-2 right-2">
                    <RiZoomInLine className="text-2xl text-black" />
                  </div>
                </div>
                <div className="relative w-full h-full flex items-center justify-center">
                  <ImageWithSkeleton
                    src={b.thumbnailUrl}
                    onClick={() =>
                      handleClickImage([b.imageUrl, ...b.imageUrls], 0)
                    }
                  />
                  <div className="absolute bottom-2 right-2">
                    <RiZoomInLine className="text-2xl text-black" />
                  </div>
                </div>
                <div className="absolute inset-0 flex items-center align-middle justify-center pointer-events-none">
                  <p className=" justify-center items-center align-middle text-accentLight text-sm sm:text-xl sm:px-4 bg-accentDark px-2 pb-0.5 rounded-full">
                    versus
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-2 mt-2 gap-2">
                <div className="flex justify-center">
                  {a.sold ? <p>Sold out :(</p> : <BuyButton product={a} />}
                </div>
                <div className="flex justify-center">
                  {b.sold ? <p>Sold out :(</p> : <BuyButton product={b} />}
                </div>
              </div>

              {/* <div className="grid grid-cols-2 gap-2">
                <Info
                  label="Category"
                  text={a.categories
                    .map(category => categoryMap[category])
                    .filter(Boolean)
                    .join(', ')}
                />
                <Info
                  label="Category"
                  text={b.categories
                    .map(category => categoryMap[category])
                    .filter(Boolean)
                    .join(', ')}
                />
              </div> */}

              <div className="grid grid-cols-2 gap-2">
                <PriceInfo
                  price={a.price}
                  discount={a.discount}
                  currency={a.currency}
                />
                <PriceInfo
                  price={b.price}
                  discount={b.discount}
                  currency={b.currency}
                />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <PriceRatingInfo product={a} />
                <PriceRatingInfo product={b} />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <Info label="Brand" text={a.brand} />
                <Info label="Brand" text={b.brand} />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <Info label="Model" text={a.model} />
                <Info label="Model" text={b.model} />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <ConditionInfo
                  condition={a.condition}
                  conditionDescription={a.conditionDescription}
                />
                <ConditionInfo
                  condition={b.condition}
                  conditionDescription={b.conditionDescription}
                />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <Info label="Color" text={a.color ?? "N/A"} />
                <Info label="Color" text={b.color ?? "N/A"} />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <Info label="Material" text={a.material} />
                <Info label="Material" text={b.material} />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <DimensionsInfo
                  length={a.length}
                  width={a.width}
                  height={a.height}
                />
                <DimensionsInfo
                  length={b.length}
                  width={b.width}
                  height={b.height}
                />
              </div>

              {/* TODO: add this at the end of description instead
            <div className="grid grid-cols-2">
              <Info label="Comes with" text={a.conditionDescription ?? 'N/A'} />
              <Info label="Comes with" text={b.conditionDescription ?? 'N/A'} />
            </div> */}

              <div className="grid grid-cols-2 gap-2">
                <Info
                  label="Description"
                  text={a.description ?? "Description not available"}
                />
                <Info
                  label="Description"
                  text={b.description ?? "Description not available"}
                />
              </div>

              {/* <div className="grid grid-cols-2 gap-2">
                <Info label="Store" text={a.store} />
                <Info label="Store" text={b.store} />
              </div> */}

              <div className="grid grid-cols-2 mt-2 gap-2">
                <div className="flex justify-center">
                  {a.sold ? <p>Sold out :(</p> : <BuyButton product={a} />}
                </div>
                <div className="flex justify-center">
                  {b.sold ? <p>Sold out :(</p> : <BuyButton product={b} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCarousel && (
        <div className="fixed inset-0 bg-background bg-opacity-75 z-50 flex justify-center items-center">
          <div className="relative bg-background w-full h-full mx-auto  flex justify-center items-center">
            <button
              className="absolute top-2 right-4 text-black text-4xl z-50"
              onClick={() => setShowCarousel(false)}
            >
              &times;
            </button>
            <Carousel images={carouselImages}></Carousel>
          </div>
        </div>
      )}
    </div>
  );
};

export default Compare;
